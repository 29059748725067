<template>
    <div>
        <el-table ref="electricAddAmmeter" :data="tableData" :span-method='mergeCols' :highlight-current-row="true" :border='true' style="width: 100%">
            <el-table-column prop="projectId" label="电费项目" min-width="140">
                <template slot-scope="{ row }">
                    <span>{{ row.projectName || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="stationId" label="站点" min-width="140">
                <template slot-scope="{ row }">
                    <span>{{ row.stationName || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="ammeterId" label="电表号" min-width="140">
                <template slot-scope="{ row }">
                    <span>{{ row.ammeterNo || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="deviceNumId" label="设备总数/插槽总数" min-width="140">
                <template slot-scope="{ row }">
                    <!-- <el-input 
                        v-model="row.deviceNumByRadio" 
                        maxlength="24" 
                        placeholder="请输入" 
                        @input='handleInput(row.deviceNumByRadio,"deviceNumByRadio",row.deviceNumId,"deviceNumId")'>
                    </el-input>
                    <span>/</span>
                    <el-input 
                        v-model="row.slotNumByRadio" 
                        maxlength="24" 
                        placeholder="请输入" 
                        @input='handleInput(row.slotNumByRadio,"slotNumByRadio",row.deviceNumId,"deviceNumId")'>
                    </el-input> -->
                    <span>{{ row.deviceNumByRadio || 0 }}/{{ row.slotNumByRadio || 0 }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="recordTimeId" label="本次抄表时间" min-width="160">
                <template slot-scope="{ row }">
                    <el-date-picker
                        value-format="yyyy-MM-dd"
                        v-model="row.recordTime"
                        @change="recordTimeChange"
                        type="date"
                        placeholder="选择日期">
                    </el-date-picker>
                </template>
            </el-table-column>
            <el-table-column prop="lastRecordTimeId" label="上次抄表时间" min-width="160">
                <template slot-scope="{ row }">
                    <span v-if="row.lastRecordTimeReadoOnly">{{ row.lastRecordTime || '-' }}</span>
                    <el-date-picker
                        v-else
                        value-format="yyyy-MM-dd"
                        v-model="row.lastRecordTime"
                        @change="lastRecordTimeChange"
                        type="date"
                        placeholder="选择日期">
                    </el-date-picker>
                </template>
            </el-table-column>
            
            <el-table-column prop="remainMoneyId" label="剩余费用" min-width="180" v-if="chargeFeeType == 1">
                <template slot-scope="{ row }">
                    <jd-input-price v-model="row.remainMoney" @input='handleInput(row.remainMoney,"remainMoney",row.remainMoneyId,"remainMoneyId")'></jd-input-price>
                </template>
            </el-table-column>
            <el-table-column prop="lastRemainMoneyId" label="上次剩余费用" min-width="100" v-if="chargeFeeType == 1">
                <template slot-scope="{ row }">
                    <span>￥{{ util.numFormat(row.lastRemainMoney) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="prePayAmountId" label="预缴费用" min-width="180" v-if="chargeFeeType == 1">
                <template slot-scope="{ row }">
                    <jd-input-price v-model="row.prePayAmount" @input='handleInput(row.prePayAmount,"prePayAmount",row.prePayAmountId,"prePayAmountId")'></jd-input-price>
                </template>
            </el-table-column>
            <el-table-column prop="lastPrePayAmountId" label="上次预缴费用" min-width="100" v-if="chargeFeeType == 1">
                <template slot-scope="{ row }">
                    <span>￥{{ util.numFormat(row.lastPrePayAmount) }}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="recordPowerId" label="本次度数（kwh）" min-width="130" v-if="chargeFeeType == 1">
                <template slot-scope="{ row }">
                    <span>{{ row.recordPower || '-' }}</span>
                </template>
            </el-table-column> -->
            <el-table-column prop="recordPowerId" label="本次度数（kwh）" min-width="180">
                <template slot-scope="{ row }">
                    <el-input v-model="row.recordPower" maxlength="24" placeholder="请输入" @input='handleInput(row.recordPower,"recordPower",row.recordPowerId,"recordPowerId")'></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="lastRecordPowerId" label="上次度数（kwh）" min-width="130">
                <template slot-scope="{ row }">
                    <span>{{ row.lastRecordPower || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="needPayAmountId" label="应付电费" min-width="180" v-if="chargeFeeType == 2">
                <template slot-scope="{ row }">
                    <jd-input-price v-model="row.needPayAmount" @input='handleInput(row.needPayAmount,"needPayAmount",row.needPayAmountId,"needPayAmountId")'></jd-input-price>
                </template>
            </el-table-column>
            <el-table-column prop="needPayAmountId" label="应付电费" min-width="180" v-if="chargeFeeType == 1">
                <template slot-scope="{ row }">
                    <span>￥{{ util.numFormat(row.needPayAmount) }}</span>
                </template>
            </el-table-column>
            
            
            
            
            <el-table-column prop="unitPriceId" label="单价（/元/kwh）" min-width="140">
                <template slot-scope="{ row }">
                    <span>￥{{ util.numFormat(row.unitPrice) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="intervalIncomeId" label="区间收益" min-width="120">
                <template slot-scope="{ row }">
                    <span>￥{{ util.numFormat(row.intervalIncome) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="stationIncomeRatioId" label="电表区间收益占比" min-width="120">
                <template slot-scope="{ row }">
                    <span>{{ (row.stationIncomeRatio*100).toFixed(2) }}%</span>
                </template>
            </el-table-column>
            <el-table-column prop="totalProfitId" label="收益合计" min-width="120">
                <template slot-scope="{ row }">
                    <span>￥{{ util.numFormat(row.totalProfit) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="totalPayId" label="应付合计" min-width="120">
                <template slot-scope="{ row }">
                    <span>￥{{ util.numFormat(row.totalPay) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="intervalRatioId" label="区间电费占比" min-width="120">
                <template slot-scope="{ row }">
                    <span>{{ row.intervalRatio || 0 }}%</span>
                </template>
            </el-table-column>
            <el-table-column prop="ratioId" label="项目总电费占比" min-width="130">
                <template slot-scope="{ row }">
                    <span>{{ row.ratio || 0 }}%</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="scrollBar">
            <scroll-bar :table-ref="$refs.electricAddAmmeter"></scroll-bar>
        </div>
    </div>
</template>

<script>
    import util from '../../../../src/utils/util'
    import scrollBar from '../../components/table/scrollBar.vue'
    export default {
        components: {
            scrollBar
        },
        data () {
            return {
                debounceTimeout: null,
                util: util,
                originTableData: []
            }
        },
        props: {
            tableData: {
                default: ()=>{
                    return []
                }
            },
            chargeFeeType: { //电费收取方式:1:预充值,2先用后付
                default: ''
            }
        },
        computed: {
            // 获取所有单元格合并数据
            spanArr() {
                if(!this.tableData.length) return []
                let mergeCols = [] // 需要合并的列（字段）
                if(this.chargeFeeType == 1) {
                    mergeCols = ['projectId','stationId','ammeterId','deviceNumId','recordTimeId','lastRecordTimeId','needPayAmountId',
                                'remainMoneyId','lastRemainMoneyId','prePayAmountId','lastPrePayAmountId','recordPowerId','lastRecordPowerId',
                                'unitPriceId','intervalIncomeId','stationIncomeRatioId',
                                'totalProfitId','totalPayId','intervalRatioId','ratioId'] // 需要合并的列（字段）
                }else if(this.chargeFeeType == 2) {
                    mergeCols = ['projectId','stationId','ammeterId','deviceNumId','recordTimeId','lastRecordTimeId','needPayAmountId','recordPowerId','lastRecordPowerId',
                                'unitPriceId','intervalIncomeId','stationIncomeRatioId',
                                'totalProfitId','totalPayId','intervalRatioId','ratioId'] // 需要合并的列（字段）
                }
                return util.getMergeCells(this.tableData, mergeCols, mergeCols)
            }
        },
        mounted () {
        },
        methods:{
            handleInput (value, prop, propValue, propId) {
                clearTimeout(this.debounceTimeout);
                this.debounceTimeout = setTimeout(() => {
                    // 防抖处理后的输入内容
                    // lastRemainMoney  上次剩余
                    // lastPrePayAmount  上次预付
                    // RemainMoney  本次剩余
                    if(propId == 'deviceNumId') { // 用户修改了设备数 插槽数后 重新计算电表区间收益占比
                        this.tableData.map((item,index)=>{
                            if(item[propId] == propValue) {
                                item[prop] = value
                            }
                            let ratioAll = 0
                            if(item.stationIncomeRatio == 1){
                                item.stationIncomeRatio = 1
                                item.slotNum = item.slotNumByRadio
                                item.deviceNum = item.deviceNumByRadio
                            }else{
                                // 找到该电表原始插槽数，与新填写的插槽数计算出差值，从而计算出新的插槽总数
                                let originData = this.originTableData.find(res=>{
                                    return res.ammeterId == item.ammeterId
                                })
                                let slotNumOrigin = originData.slotNumByRadio
                                let deviceNumOrigin = originData.deviceNumByRadio

                                let slotNumDiff = Number(item.slotNumByRadio) - Number(slotNumOrigin)
                                let deviceNumDiff = Number(item.deviceNumByRadio) - Number(deviceNumOrigin)

                                let slotNumAll = item.slotNum + slotNumDiff
                                let deviceNumAll = item.deviceNum + deviceNumDiff

                                item.stationIncomeRatio = Number((item.slotNumByRadio / slotNumAll).toFixed(4))
                                ratioAll += Number((item.slotNumByRadio / slotNumAll).toFixed(4))

                                item.slotNum = slotNumAll
                                item.deviceNum = deviceNumAll
                            }
                        })
                    }

                    let uniqArr = JSON.parse(JSON.stringify(this.tableData))
                    uniqArr = this.uniqueArrAmmeter(uniqArr)
                    let totalPay = 0 //所有电表的应付合计
                    let totalIntervalPower = 0 //所有电表的电量度数合计
                    let needPayAmountObj = {} // 存储每一个电表的应付费用，预付时用
                    let currentPowerObj = {} // 存储每一个电表的本次度数，预付时用
                    let stationAndAmmeterProfitObj = {} // 存储每一个电表对应的站点的收益费用总值，用于计算每一个电表的区间收益占比
                    uniqArr.map(item=>{
                        if(this.chargeFeeType == 1) {
                            // 计算先付费应付合计
                            if((item.lastRemainMoney || item.lastRemainMoney === 0) && (item.lastPrePayAmount || item.lastPrePayAmount === 0)) {
                                let amount = (Number(item.lastRemainMoney) + Number(item.lastPrePayAmount) - (Number((item.remainMoney*100).toFixed(0)) || 0))
                                totalPay += amount

                                this.$set(needPayAmountObj,item.ammeterId,amount)
                            }else {
                                totalPay += 0
                                this.$set(needPayAmountObj,item.ammeterId,0)
                            }
                        }else if(this.chargeFeeType == 2) {
                            // 计算应付电费
                            if(propId == 'needPayAmountId'){ 
                                // 如果是用户正在输入应付电费，则以用户输入为准
                                totalPay += (Number(item.needPayAmount*100) || 0)
                            }else if(item.recordPower && item.lastRecordPower && item.unitPrice) {
                                // 如果不是用户输入的应付电费，则以上次度数和本次度数之差*电费单价为准
                                let amount = (Number(item.recordPower || 0) - Number(item.lastRecordPower || 0)) * Number(item.unitPrice || 0)
                                // 计算后付费应付合计
                                amount = Number((amount / 100).toFixed(2))
                                totalPay += Number((amount*100).toFixed(0))
                                this.$set(needPayAmountObj,item.ammeterId,amount)
                            }else{
                                totalPay += 0
                                this.$set(needPayAmountObj,item.ammeterId,0)
                            }
                        }
                        if(propId == 'recordPowerId'){ 
                            // 如果是用户正在输入本次度数，则以用户输入为准
                            this.$set(currentPowerObj,item.ammeterId,item.recordPower)
                        }else {
                            let currentPower
                            if(needPayAmountObj[item.ammeterId] && item.unitPrice) {
                                currentPower = parseInt(needPayAmountObj[item.ammeterId] / item.unitPrice + (item.lastRecordPower || 0))
                            }else {
                                currentPower = ''
                            }
                            this.$set(currentPowerObj,item.ammeterId,currentPower || currentPower === 0 ? JSON.stringify(currentPower) : '')
                        }
                        totalIntervalPower += Number(item.recordPower || 0)

                        // 计算每一个电表对应的站点的区间收益总值
                        let stationAndAmmeterIncome = 0
                        let stationAndAmmeterIncomeList = this.tableData.filter(res=>{ // 筛选出每一个电表对应的所有站点集合
                            return res.ammeterId == item.ammeterId
                        })
                        stationAndAmmeterIncomeList.map(ite=>{  //将筛选出的站点集合循环，计算出站点集合的总区间收益
                            stationAndAmmeterIncome += (Number(ite.intervalIncome || 0) * Number(ite.stationIncomeRatio))
                        })
                        this.$set(stationAndAmmeterProfitObj,item.ammeterId,stationAndAmmeterIncome)
                    })

                    this.tableData.map((item,index)=>{
                        if(item[propId] == propValue) {
                            item[prop] = value
                        }
                        item.totalPay = totalPay //所有电表的应付合计
                        item.totalIntervalPower = totalIntervalPower //所有电表的电量度数合计
                        if(propId != 'needPayAmountId'){
                            item['needPayAmount'] = needPayAmountObj[item.ammeterId]
                        }
                        if(propId != 'recordPowerId'){
                            item['recordPower'] = currentPowerObj[item.ammeterId]
                        }
                        //计算区间收益电费占比
                        if((item.needPayAmount || item.needPayAmount === 0 || item.needPayAmount === '0') && stationAndAmmeterProfitObj[item.ammeterId]){ 
                            // 每一个电表的应付金额 / 每一个电表（站点集合）的区间收益 = 该电表的区间收益占比
                            if(this.chargeFeeType == 1) {
                                item.intervalRatio = parseInt((Number(item.needPayAmount) / Number(stationAndAmmeterProfitObj[item.ammeterId]))*10000)/100
                            }else{
                                item.intervalRatio = parseInt((Number(item.needPayAmount * 100) / Number(stationAndAmmeterProfitObj[item.ammeterId]))*10000)/100
                            }
                        } else if(Number(item.needPayAmount) && !Number(stationAndAmmeterProfitObj[item.ammeterId]) ){//&& stationAndAmmeterProfitObj[item.ammeterId] !== 0 && stationAndAmmeterProfitObj[item.ammeterId] !== '0'
                            item.intervalRatio = 100
                        } else {
                            item.intervalRatio = 0
                        }
                        // 计算项目总电费占比
                        if((item.totalPay || item.totalPay === 0 || item.totalPay === '0') && item.totalProfit) {
                            item.ratio = parseInt((Number(item.totalPay) / Number(item.totalProfit))*10000)/100
                        }else if(Number(item.totalPay) && !Number(item.totalProfit) ) {//&& item.totalProfit !== '0' && item.totalProfit !== 0
                            item.ratio = 100
                        }else {
                            item.ratio = 0
                        }

                    })
                    this.$emit('update:tableData', this.tableData)
                }, 100);
            },
            recordTimeChange (val) {
                this.tableData.map(item=>{
                    item.recordTime = val
                })
                this.$emit('update:tableData', this.tableData)
                this.$emit('recordTimeChange',val)
            },
            lastRecordTimeChange (val) {
                this.tableData.map(item=>{
                    item.lastRecordTime = val
                })
                this.$emit('update:tableData', this.tableData)
                this.$emit('lastRecordTimeChange',val)
            },
            // 合并行
            mergeCols ({ row, column, rowIndex, columnIndex }) {
                return this.spanArr[rowIndex][columnIndex]
            },
            groupById(arr,key) {
                const result = {};
                arr.forEach(item => {
                    if (!result[item[key]]) {
                    result[item[key]] = [];
                    }
                    result[item[key]].push(item);
                });
                return result;
            },
            //数组去重
            uniqueArr (arr) {
                try{
                    for(var i=0; i<arr.length; i++){
                        for(var j=i+1; j<arr.length; j++){
                            if(arr[i].stationId==arr[j].stationId){         //第一个等同于第二个，splice方法删除第二个
                                arr.splice(j,1);
                                j--;
                            }
                        }
                    }
                    return arr;
                }catch(err){
                }
            },
            uniqueArrAmmeter (arr) {
                try{
                    for(var i=0; i<arr.length; i++){
                        for(var j=i+1; j<arr.length; j++){
                            if(arr[i].ammeterId==arr[j].ammeterId){         //第一个等同于第二个，splice方法删除第二个
                                arr.splice(j,1);
                                j--;
                            }
                        }
                    }
                    return arr;
                }catch(err){
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.scrollBar{
    left: 200px ;
    right: 48px ;
    position: fixed;
    bottom: 70px;
    background: #fff;
    z-index: 100;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
/deep/ .el-input-group__prepend{
    height: 32px;
    line-height: 32px;
}
/deep/ .el-date-editor.el-input{
    width: 140px !important;
}
</style>